// API Constants
export const API_BASE_URL =
  process.env.REACT_APP_API_URL || 'https://dev.pedahl.no/api';
export const API_TIMEOUT = 30000; // 30 seconds

// Authentication
export const TOKEN_KEY = 'token';
export const AUTH_HEADER = 'Authorization';

// Route Paths
export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  NOT_FOUND: '/404',
};

// Animation Variants
export const FADE_IN = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const SLIDE_UP = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: -20 },
};

// Breakpoints
export const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

// Map Settings
export const MAP_CONFIG = {
  defaultZoom: 15,
  defaultCenter: { lat: 59.9139, lng: 10.7522 }, // Oslo coordinates
  maxZoom: 18,
  minZoom: 3,
};

// Contact Form Fields
export const FORM_FIELDS = {
  name: {
    required: true,
    minLength: 2,
    maxLength: 50,
  },
  email: {
    required: true,
    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  },
  message: {
    required: true,
    minLength: 10,
    maxLength: 500,
  },
};

// Error Messages
export const ERROR_MESSAGES = {
  general: 'Something went wrong. Please try again.',
  network: 'Network error. Please check your connection.',
  unauthorized: 'Please log in to continue.',
  notFound: 'The requested resource was not found.',
  validation: 'Please check your input and try again.',
};

// Success Messages
export const SUCCESS_MESSAGES = {
  login: 'Successfully logged in!',
  logout: 'Successfully logged out',
  messageSent: 'Your message has been sent successfully!',
};

// Timeouts
export const TIMEOUTS = {
  toast: 5000,
  animation: 300,
  debounce: 500,
};

// Local Storage Keys
export const STORAGE_KEYS = {
  theme: 'preferred-theme',
  language: 'preferred-language',
  user: 'user-data',
};

// Content Limits
export const CONTENT_LIMITS = {
  titleMaxLength: 100,
  descriptionMaxLength: 500,
  imageMaxSize: 5 * 1024 * 1024, // 5MB
};
