export const navItems = [
  {
    label: 'Home',
    href: '#hero',
    icon: 'Home',
  },
  {
    label: 'About',
    href: '#whoweare',
    icon: 'Info',
  },
  {
    label: 'Services',
    href: '#services',
    icon: 'Package',
  },
  {
    label: 'Expertise',
    href: '#expertise',
    icon: 'Award',
  },
  {
    label: 'Reviews',
    href: '#reviews',
    icon: 'Star',
  },
  {
    label: 'Contact',
    href: '#contact',
    icon: 'Mail',
  },
];

export const footerLinks = {
  company: [
    { label: 'About Us', href: '#whoweare' },
    { label: 'Our Services', href: '#services' },
    { label: 'Careers', href: '#careers' },
    { label: 'Contact Us', href: '#contact' },
  ],
  legal: [
    { label: 'Privacy Policy', href: '/privacy' },
    { label: 'Terms of Service', href: '/terms' },
    { label: 'Cookie Policy', href: '/cookies' },
  ],
  social: [
    { label: 'LinkedIn', href: 'https://linkedin.com', icon: 'Linkedin' },
    { label: 'Twitter', href: 'https://twitter.com', icon: 'Twitter' },
    { label: 'Facebook', href: 'https://facebook.com', icon: 'Facebook' },
    { label: 'Instagram', href: 'https://instagram.com', icon: 'Instagram' },
  ],
};

export const mobileMenuConfig = {
  transition: {
    type: 'spring',
    duration: 0.3,
    bounce: 0.2,
  },
  variants: {
    closed: {
      x: '100%',
    },
    open: {
      x: 0,
    },
  },
};

export const scrollConfig = {
  smooth: true,
  duration: 500,
  offset: -64, // Height of navbar
};

export const dropdownConfig = {
  variants: {
    hidden: {
      opacity: 0,
      y: -10,
      transition: {
        duration: 0.2,
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.2,
      },
    },
  },
};
