import axios from 'axios';

const API_BASE_URL = 'https://dev.pedahl.no/api';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add request interceptor to include auth token
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const fetchHeroData = async () => {
  try {
    const response = await api.get('/hero-sections?populate=*');
    return response.data.data[0];
  } catch (error) {
    console.error('Error fetching hero data:', error);
    throw error;
  }
};

export const fetchAboutData = async () => {
  try {
    const response = await api.get('/who-we-ares?populate=*');
    return response.data.data[0];
  } catch (error) {
    console.error('Error fetching about data:', error);
    throw error;
  }
};

// Add more API functions as needed

export default api;
