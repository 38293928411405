import React, { Suspense } from 'react';
import { Box, CircularProgress } from '@mui/material';

// Lazy loaded components
const HeroSection = React.lazy(
  () => import('@/components/sections/HeroSection')
);
const WhoWeAreSection = React.lazy(
  () => import('@/components/sections/WhoWeAreSection')
);
const ServicesSection = React.lazy(
  () => import('@/components/sections/ServicesSection')
);
const ExpertiseSection = React.lazy(
  () => import('@/components/sections/ExpertiseSection')
);
const ReviewsSection = React.lazy(
  () => import('@/components/sections/ReviewsSection')
);
const FindUsSection = React.lazy(
  () => import('@/components/sections/FindUsSection')
);
const ContactFormSection = React.lazy(
  () => import('@/components/sections/ContactFormSection')
);

// Loading fallback component
const SectionLoader = () => (
  <Box
    sx={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <CircularProgress />
  </Box>
);

const Home = () => {
  return (
    <Box>
      <Suspense fallback={<SectionLoader />}>
        <HeroSection />
      </Suspense>

      <Suspense fallback={<SectionLoader />}>
        <WhoWeAreSection />
      </Suspense>

      <Suspense fallback={<SectionLoader />}>
        <ServicesSection />
      </Suspense>

      <Suspense fallback={<SectionLoader />}>
        <ExpertiseSection />
      </Suspense>

      <Suspense fallback={<SectionLoader />}>
        <ReviewsSection />
      </Suspense>

      <Suspense fallback={<SectionLoader />}>
        <FindUsSection />
      </Suspense>

      <Suspense fallback={<SectionLoader />}>
        <ContactFormSection />
      </Suspense>
    </Box>
  );
};

export default Home;
