import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Box,
  Container,
} from '@mui/material';
import { Menu as MenuIcon, ChevronRight } from 'lucide-react';
import { navItems } from '@/config/navigation';
import { motion } from 'framer-motion';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <motion.div
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <AppBar
        position="fixed"
        elevation={scrolled ? 4 : 0}
        sx={{
          background: scrolled ? 'rgba(255, 255, 255, 0.95)' : 'transparent',
          transition: 'all 0.3s ease',
          backdropFilter: scrolled ? 'blur(10px)' : 'none',
        }}
      >
        <Container maxWidth="lg">
          <Toolbar sx={{ justifyContent: 'space-between', py: 1 }}>
            <Typography
              variant="h6"
              sx={{
                color: scrolled ? 'primary.main' : 'white',
                fontWeight: 700,
                fontSize: '1.5rem',
              }}
            >
              Huce
            </Typography>

            {isMobile ? (
              <>
                <IconButton
                  edge="end"
                  onClick={() => setIsOpen(true)}
                  sx={{ color: scrolled ? 'primary.main' : 'white' }}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor="right"
                  open={isOpen}
                  onClose={() => setIsOpen(false)}
                  PaperProps={{
                    sx: {
                      width: '250px',
                      background: 'rgba(255, 255, 255, 0.95)',
                    },
                  }}
                >
                  <List>
                    {navItems.map((item) => (
                      <ListItem
                        button
                        key={item.label}
                        onClick={() => {
                          setIsOpen(false);
                          document
                            .querySelector(item.href)
                            .scrollIntoView({ behavior: 'smooth' });
                        }}
                      >
                        <ListItemText
                          primary={item.label}
                          primaryTypographyProps={{
                            sx: { fontWeight: 500 },
                          }}
                        />
                        <ChevronRight size={20} />
                      </ListItem>
                    ))}
                  </List>
                </Drawer>
              </>
            ) : (
              <Box sx={{ display: 'flex', gap: 2 }}>
                {navItems.map((item) => (
                  <Button
                    key={item.label}
                    href={item.href}
                    sx={{
                      color: scrolled ? 'primary.main' : 'white',
                      fontWeight: 500,
                      '&:hover': {
                        backgroundColor: scrolled
                          ? 'rgba(52, 128, 31, 0.08)'
                          : 'rgba(255, 255, 255, 0.1)',
                      },
                    }}
                  >
                    {item.label}
                  </Button>
                ))}
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
    </motion.div>
  );
};

export default Navbar;
