import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { motion } from 'framer-motion';
import { Home, ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { FADE_IN, SLIDE_UP } from '@/utils/constants';

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box
      component={motion.div}
      initial="initial"
      animate="animate"
      exit="exit"
      variants={FADE_IN}
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(45deg, #1a237e 30%, #0d47a1 90%)',
        color: 'white',
        textAlign: 'center',
        py: 4,
      }}
    >
      <Container maxWidth="sm">
        <motion.div variants={SLIDE_UP}>
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '6rem', sm: '8rem' },
              fontWeight: 700,
              mb: 2,
              textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
            }}
          >
            404
          </Typography>

          <Typography
            variant="h4"
            sx={{
              mb: 3,
              fontWeight: 500,
              textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
            }}
          >
            Page Not Found
          </Typography>

          <Typography
            variant="body1"
            sx={{
              mb: 4,
              fontSize: '1.1rem',
              opacity: 0.9,
            }}
          >
            The page you're looking for doesn't exist or has been moved.
          </Typography>

          <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center' }}>
            <Button
              variant="contained"
              startIcon={<Home />}
              onClick={() => navigate('/')}
              sx={{
                px: 3,
                py: 1.5,
                bgcolor: 'white',
                color: 'primary.main',
                '&:hover': {
                  bgcolor: 'grey.100',
                  transform: 'translateY(-2px)',
                },
                transition: 'all 0.3s ease',
              }}
            >
              Go Home
            </Button>

            <Button
              variant="outlined"
              startIcon={<ArrowLeft />}
              onClick={() => navigate(-1)}
              sx={{
                px: 3,
                py: 1.5,
                borderColor: 'white',
                color: 'white',
                '&:hover': {
                  borderColor: 'grey.300',
                  bgcolor: 'rgba(255, 255, 255, 0.1)',
                  transform: 'translateY(-2px)',
                },
                transition: 'all 0.3s ease',
              }}
            >
              Go Back
            </Button>
          </Box>

          {/* Decorative elements */}
          <Box
            component={motion.div}
            animate={{
              y: [0, -10, 0],
              transition: {
                duration: 2,
                repeat: Infinity,
                repeatType: 'reverse',
              },
            }}
            sx={{
              mt: 6,
              opacity: 0.6,
              '& > *': {
                display: 'inline-block',
                mx: 1,
              },
            }}
          >
            <Typography variant="h5">⚡</Typography>
            <Typography variant="h5">🔍</Typography>
            <Typography variant="h5">🌟</Typography>
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
};

export default NotFound;
